import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import StyleProps from '@workday/canvas-kit-docs/dist/mdx/style-props/STYLE_PROPS.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Breadcrumbs = makeShortcode("Breadcrumbs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Breadcrumbs items={[{
      name: 'Resources',
      to: '/get-started/for-developers/resources'
    }, {
      name: 'Style Props'
    }]} mdxType="Breadcrumbs" />
    <StyleProps mdxType="StyleProps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      